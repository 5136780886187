import jwtAxios from "@crema/services/auth/jwt-auth/jwt-api";
import env from 'react-dotenv';
import { AuthToken } from "types/models/AuthToken";

export function onGetToken(code: string) : Promise<AuthToken> {

    const url = env.GATEWAYPATH+`/v1/admin/token`

    const dataToReturn = jwtAxios.get(url, {
      params: {code: code} 
    })
    .then(data => { 
        if (data.status === 200) {
            //const access_token = data.data.access_token;
            var expires = new Date();
            expires.setSeconds(expires.getSeconds() + data.data.expires_in - 600);

            // console.log('onGetToken: data found expires at ' + expires + " refresh " + data.data.refresh_token);

            const auth_token : AuthToken = {
                access_token: data.data.access_token,
                refresh_token: data.data.refresh_token,
                expires_in: data.data.expires_in,
                expires_at: expires
            };
            return auth_token as AuthToken;
        } else {
            console.log("onGetToken: non-200 ", data)
            throw new Error(`${data}`)
        }
    })
    .catch(error => {
        console.log("onGetToken: error ", error)
        throw error
    });

    return dataToReturn;
};

export function onRefreshToken(refresh: string) : Promise<AuthToken> {

    const url = env.GATEWAYPATH+`/v1/admin/refresh`

    const dataToReturn = jwtAxios.get(url, {
      params: {refresh: refresh} 
    })
    .then(data => { 
        if (data.status === 200) {
            // console.log('onGetToken: data found ');
            //const access_token = data.data.access_token;
            var expires = new Date();
            expires.setHours(expires.getSeconds() + data.data.expires_in - 600);

            const auth_token : AuthToken = {
                access_token: data.data.access_token,
                refresh_token: data.data.refresh_token,
                expires_in: data.data.expires_in,
                expires_at: expires
            };
            return auth_token as AuthToken;
        } else {
            console.log("onGetToken: non-200 ", data)
            throw new Error(`${data}`)
        }
    })
    .catch(error => {
        console.log("onGetToken: error ", error)
        throw error
    });

    return dataToReturn;
};