import React from 'react';

export const queuePagesConfig = [
  {
    auth: ['admin', 'user', 'viewer'],
    routes: [
      {
        path: '/queue/details/:messageId',
        component: React.lazy(() => import('./Pages/MessageDetails')),
      },
      {
        path: '/queue',
        component: React.lazy(() => import('./Pages/QueueLists')),
      },
    ],
  },
];
