import { Box } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import env from 'react-dotenv';
import AppContext from '../../../@crema/utility/AppContext';
import AppContextPropsType, { CremaTheme } from '../../../types/AppContextPropsType';
import { ThemeMode } from '../../constants/AppEnums';

const AppLogo = () => {
  const {themeMode} = useContext<AppContextPropsType>(AppContext);
  const useStyles = makeStyles((theme: CremaTheme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    environment: {
      background: theme.palette.sidebar.bgColor,
      color: 'white',
      padding: '7px 40px',
      marginLeft: '10px',
      fontSize: '16px',
      borderRadius: '12px 0 12px 0',
      textAlign: 'center',
      textTransform: 'uppercase',
      boxShadow: '1px 1px 4px #888888',
    },
    logo: {
      height: 36,
      marginRight: 10,
    },
  }));
  const classes = useStyles();

  function getEnvironmentBadge() {
    switch (env.ENVIRONMENT) {
      case 'Development':
        return 'localhost';
      case 'Production': {
        return 'prod'
      }
      default: return env.ENVIRONMENT;
    }
  }

  return (
    <Box className={classes.root}>
      <Hidden smUp>
        <Box component='h1' className={classes.environment}>
          {getEnvironmentBadge()}
        </Box>
        <img
          className={classes.logo}
          src={
            themeMode === ThemeMode.DARK
              ? '/assets/images/brightcom-logo-white.svg'
              : '/assets/images/brightcom-logo-blue.svg'
          }
          alt='BrightCom AB'
        />
      </Hidden>
      <Hidden xsDown>
        <Box component='h1' className={classes.environment}>
          {getEnvironmentBadge()}
        </Box>
        <img
          className={classes.logo}
          src={
            themeMode === ThemeMode.DARK
              ? '/assets/images/brightcom-logo-white.svg'
              : '/assets/images/brightcom-logo-blue.svg'
          }
          alt='BrightCom AB'
        />
      </Hidden>
    </Box>
  );
};

export default AppLogo;
