import React from 'react';

export const mappingPagesConfig = [
  {
    auth: ['admin', 'user', 'viewer'],
    routes: [
      {
        path: '/mapping-list',
        component: React.lazy(() => import('./Pages/MappingList')),
      },
    ],
  },
];
