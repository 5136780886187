import React from 'react';

export const toolboxPagesConfig = [
  {
    auth: ['admin', 'user', 'viewer'],
    routes: [
      {
        path: '/toolbox',
        component: React.lazy(() => import('./Pages/Toolbox')),
      },
    ],
  },
];
