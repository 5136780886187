import React from 'react';

export const authRouteConfig = [
  {
    routes: [
      {
        path: '/signin',
        component: React.lazy(() => import('./Signin')),
      },
      {
        path: '/redirect',
        component: React.lazy(() => import('./Redirect')),
      },
    ],
  }
];
