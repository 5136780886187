import React from 'react';

export const externalItemsPagesConfig = [
  {
    auth: ['admin', 'user', 'viewer'],
    routes: [
      {
        path: '/externalitems',
        component: React.lazy(() => import('./Pages/ExternalItemsList')),
      },
    ],
  },
];
