import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@crema/utility/Utils';
import {errorPagesConfigs} from './errorPages';
import {authRouteConfig} from './msal-auth';
import {initialUrl} from '../shared/constants/AppConst';
import { tenantPagesConfig } from './tenant';
import { brightcomDashboardPagesConfig } from './brightcom-dashboard';
import { queuePagesConfig } from './queue';
import { retryQueuePagesConfig } from './retryqueue';
import { deadletterPagesConfig } from './deadletter';
import { jobStatusPagesConfig } from './jobstatus';
import { subtenantPagesConfig } from './subtenant';
import { mappingPagesConfig } from './mapping';
import { configurationPagesConfig } from './configuration';
import { externalItemsPagesConfig } from './externalitems';
import { externalMessagesPagesConfig } from './externalMessages';
import { toolboxPagesConfig } from './toolbox';
import { entityPagesConfig } from './entity';

const routeConfigs = [
  ...errorPagesConfigs,
  ...authRouteConfig,
  ...tenantPagesConfig,
  ...brightcomDashboardPagesConfig,
  ...queuePagesConfig,
  ...retryQueuePagesConfig,
  ...deadletterPagesConfig,
  ...jobStatusPagesConfig,
  ...subtenantPagesConfig,
  ...mappingPagesConfig,
  ...configurationPagesConfig,
  ...externalItemsPagesConfig,
  ...externalMessagesPagesConfig,
  ...entityPagesConfig,
  ...toolboxPagesConfig
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
