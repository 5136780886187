import React from 'react';

export const deadletterPagesConfig = [
  {
    auth: ['admin', 'user', 'viewer'],
    routes: [
      {
        path: '/deadletter/details/:queue/:id',
        component: React.lazy(() => import('./Pages/DeadletterDetails')),
      },
      {
        path: '/deadletter-list',
        component: React.lazy(() => import('./Pages/DeadletterList')),
      },
    ],
  },
];
