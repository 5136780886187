import { Tenant, TenantWithYaml } from "types/models/TenantTypes";

export const GET_TENANT_LIST = 'GET_TENANT_LIST';
export const GET_SELECTED_TENANT = 'GET_SELECTED_TENANT';
export const UPDATE_SELECTED_TENANT = 'UPDATE_SELECTED_TENANT';
export const SET_ACTIVE_TENANT = 'SET_ACTIVE_TENANT';

export interface GetTenantList {
    type: typeof GET_TENANT_LIST;
    payload: Tenant[];
}

export interface GetSelectedTenant {
    type: typeof GET_SELECTED_TENANT;
    payload: TenantWithYaml;
}

export interface UpdateSelectedTenant {
    type: typeof UPDATE_SELECTED_TENANT;
}

export interface SetActiveTenant {
    type: typeof SET_ACTIVE_TENANT;
    payload: Tenant;
}

export type TenantActionTypes =
| GetTenantList
| GetSelectedTenant
| UpdateSelectedTenant
| SetActiveTenant