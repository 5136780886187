import axios from 'axios';
import { onRefreshToken } from 'modules/msal-auth/msalRequest';
import { AuthToken } from 'types/models/AuthToken';

const jwtAxios = axios.create({
  baseURL: 'https://adminui.onbrightcom.online/', //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});
jwtAxios.interceptors.response.use(
  res => res,
  err => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
      // store.dispatch({type: LOGOUT});
      onRefreshToken(localStorage.getItem('refresh_token')||'')
      .then(x=> {
        console.log('Token refreshed ' + JSON.stringify(x));

        setAuthToken(x);
      });      
    }
    return Promise.reject(err);
  },
);
export const setAuthToken = (token: AuthToken | null) => {
  if (token) {
    jwtAxios.defaults.headers.common['authorization'] = 'Bearer ' + token.access_token;
    localStorage.setItem('token', token.access_token);
    localStorage.setItem('refresh_token', token.access_token);
  } else {
    delete jwtAxios.defaults.headers.common['authorization'];
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
  }
};

export default jwtAxios;
