import React from 'react';

export const tenantPagesConfig = [
  {
    auth: ['admin', 'user', 'viewer'],
    routes: [
      {
        path: '/tenant-list',
        component: React.lazy(() => import('./Pages/TenantList')),
      },
      {
        path: '/tenant/:tenantCode',
        component: React.lazy(() => import('./Pages/TenantDetails')),
      },
    ],
  },
];
