import React from 'react';

export const jobStatusPagesConfig = [
  {
    auth: ['admin', 'user', 'viewer'],
    routes: [
      {
        path: '/jobstatus-list',
        component: React.lazy(() => import('./Pages/JobStatusLists')),
      },
    ],
  },
];
