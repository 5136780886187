import React from 'react';
import { ROLE_ADMIN } from 'shared/utils/authRoles';

export const brightcomDashboardPagesConfig = [
  {
    auth: [ROLE_ADMIN],
    routes: [
      {
        path: '/brightcom-dashboard',
        component: React.lazy(() => import('./Pages/BrightComDashboard')),
      },
    ],
  },
];
