import { ROLE_ADMIN } from "shared/utils/authRoles";

export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  {
    id: 'brightcom-dashboard',
    title: 'Brightcom Dashboard',
    messageId: 'sidebar.brightcom-dashboard',
    type: 'item',
    url: '/brightcom-dashboard',
    icon: 'assessment',
    auth: [ROLE_ADMIN],
  },
  {
    id: 'tenant-list',
    title: 'Tenants',
    messageId: 'sidebar.tenant-list',
    type: 'item',
    icon: 'business',
    url: '/tenant-list',
  },

  {
    id: 'subtenant-list',
    title: 'Subtenants',
    messageId: 'sidebar.subtenant-list',
    type: 'item',
    icon: 'storefront',
    url: '/subtenant-list',
  },
  {
    id: 'queue',
    title: 'Queue',
    messageId: 'sidebar.queue',
    type: 'item',
    icon: 'subscriptions',
    url: '/queue',
  },
  {
    id: 'retryqueue',
    title: 'Retry Queue',
    messageId: 'sidebar.retryqueue',
    type: 'item',
    icon: 'replay',
    url: '/retryqueue',
  },
  {
    id: 'deadletter-list',
    title: 'Deadletters',
    messageId: 'sidebar.deadletter-list',
    type: 'item',
    icon: 'erroroutline',
    url: '/deadletter-list',
  },
  {
    id: 'jobstatus-list',
    title: 'Job status',
    messageId: 'sidebar.jobstatus-list',
    type: 'item',
    icon: 'work',
    url: '/jobstatus-list',
  },
  {
    id: 'externalitems',
    title: 'External items',
    messageId: 'sidebar.externalitems',
    type: 'item',
    icon: 'translate',
    url: '/externalitems',
  },
  {
    id: 'externalmessages',
    title: 'External messages',
    messageId: 'sidebar.externalmessages',
    type: 'item',
    icon: 'assignment',
    url: '/externalmessages',
  },
  {
    id: 'entities',
    title: 'Entities',
    messageId: 'sidebar.entities',
    type: 'item',
    icon: 'fingerprint',
    url: '/entities',
    auth: [ROLE_ADMIN],
  },
  {
    id: 'mapping-list',
    title: 'Integration Mappings',
    messageId: 'sidebar.mapping-list',
    type: 'item',
    icon: 'map',
    url: '/mapping-list',
  },
  {
    id: 'configuration',
    title: 'Configuration',
    messageId: 'sidebar.configuration',
    type: 'item',
    icon: 'settings',
    url: '/configuration',
    auth: [ROLE_ADMIN],
  },
  {
    id: 'toolbox',
    title: 'Toolbox',
    messageId: 'sidebar.toolbox',
    type: 'item',
    icon: 'build',
    url: '/toolbox',
  },
];
export default routesConfig;
