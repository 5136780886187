
import { GET_SELECTED_TENANT, GET_TENANT_LIST, SET_ACTIVE_TENANT, UPDATE_SELECTED_TENANT } from 'types/actions/Tenant.actions';
import { Tenant, TenantWithYaml } from 'types/models/TenantTypes';
import {AppActions} from '../../types';


const initialState: {
    tenantList: Tenant[];
    selectedTenant: TenantWithYaml | null;
    activeTenant: Tenant | null;

   } = {
    tenantList: [],
    selectedTenant: null,
    activeTenant: null,
  };

const TenantReducer = (state = initialState, action: AppActions ) => {
  switch (action.type) {
    case GET_TENANT_LIST: 
      return {
        ...state,
        //selectedTenant: null, // Clear selected tenant. 
        // NOTE: ^This is commented out as it caused the selectedTenant to become null if one clicked the Config button
        // too fast before the tenant list was loaded. Do keep an eye out for any side effects that this may cause.
        tenantList: action.payload
      }
    case GET_SELECTED_TENANT:
      return {
        ...state,
        selectedTenant: action.payload
      }
    case UPDATE_SELECTED_TENANT:
      return {
        ...state,
      }
    case SET_ACTIVE_TENANT:
      return {
        ...state,
        activeTenant: action.payload
      }
    default:
      return state;
  }
}  

export default TenantReducer;
