import React from 'react';

export const retryQueuePagesConfig = [
  {
    auth: ['admin', 'user', 'viewer'],
    routes: [
    //   {
    //     path: '/retryqueue/details/:messageId',
    //     component: React.lazy(() => import('./Pages/MessageDetails')),
    //   },
      {
        path: '/retryqueue',
        component: React.lazy(() => import('./Pages/RetryQueueList')),
      },
    ],
  },
];
