import { useSelector } from "react-redux";
import { AppState } from "redux/store";
import { AuthUser } from "types/models/AuthUser";

export const ROLE_ADMIN = "admin";
export const ROLE_USER = "user";
export const ROLE_VIEWER = "viewer";

// Create hooks for checking if user is Admin, User, or Viewer

// Admin
export function useIsRoleAdmin() {
    // Redux store
    const {user} : {user: AuthUser | null} = useSelector<AppState, AppState['auth']>(({auth}) => auth);

    // Check if user is admin
    return (user && user.role.includes(ROLE_ADMIN)) || false;
}

// User
export function useIsRoleUser() {
    // Redux store
    const {user} : {user: AuthUser | null} = useSelector<AppState, AppState['auth']>(({auth}) => auth);

    // Check if user is user
    return (user && user.role.includes(ROLE_USER)) || false;
}

// Viewer
export function useIsRoleViewer() {
    // Redux store
    const {user} : {user: AuthUser | null} = useSelector<AppState, AppState['auth']>(({auth}) => auth);

    // Check if user is viewer
    return (user && user.role.includes(ROLE_VIEWER)) || false;
}


