import React from 'react';

export const entityPagesConfig = [
  {
    auth: ['admin', 'user', 'viewer'],
    routes: [
      {
        path: '/entities',
        component: React.lazy(() => import('./Pages/EntityList')),
      },
    ],
  },
];
