import React from 'react';

export const subtenantPagesConfig = [
  {
    auth: ['admin', 'user', 'viewer'],
    routes: [
      {
        path: '/subtenant-list',
        component: React.lazy(() => import('./Pages/SubtenantList')),
      },
      {
        path: '/subtenant/:subtenantCode/dashboard',
        component: React.lazy(() => import('./Pages/SubtenantDashboard')),
      },
    ],
  },
];
