import { ActionBarSubtenantEndpoints } from 'types/models/ActionBarSubtenantEndpoints';
import {AuthUser} from '../../types/models/AuthUser';
import {AuthType} from './AppEnums';

export const authRole = {
  admin: ['admin'],
  user: ['user', 'admin'],
};

export const defaultUser: AuthUser = {
  uid: 'RFedvhji876rfhjuecvh7',
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: authRole.user,
  authType: AuthType.AUTH0,
  photoURL: '/assets/images/avatar/A11.jpg',
  groups: []
};
export const initialUrl = '/tenant-list'; // this url will open after login

// NOTE: Remember to add any new subtenants to the switch-case statement in modules/subtenant/Pages/SubtenantDashboard/index.tsx
export const actionBarConnectorEndpoints: {[key: string]: ActionBarSubtenantEndpoints} =
{
  sitoo: {
    baseUrl: '/sitoo',
    baseUrlDev: '/sitoodev',
    nextItems: 'items/next',
    nextOrders: 'orders/next',
    nextPrices: 'prices/next',
    nextWarehouse: 'warehouse/next',
    resendOrder: 'orders/next?retry='
  },
  shopify: {
    baseUrl: '/shopify/v1',
    baseUrlDev: '/shopify-dev/v1',
    nextItems: 'item',
    nextOrders: 'order',
    nextPrices: 'price',
    nextWarehouse: 'inventory',
    resendOrder: 'order?retry='
  },
  colect: {
    baseUrl: '/colect/v1',
    baseUrlDev: '/colectdev/v1',
    nextItems: 'item/next',
    nextOrders: 'order/next',
    nextPrices: 'price/next',
    nextWarehouse: 'warehouse/next',
    nextShipment: 'shipment/next',
    nextCustomer: 'customer/next',
  },
  storm: {
    baseUrl: '/storm/v1',
    baseUrlDev: '/stormdev/v1',
    nextItems: 'item/next',
    nextPrices: 'price/next',
    nextWarehouse: 'warehouse/next',
  },
  wgr: {
    baseUrl: '/wikinggruppen/v1',
    baseUrlDev: '/wikinggruppen-dev/v1',
    nextItems: 'items/next',
    nextPrices: 'prices/next',
    nextWarehouse: 'stock/next',
    nextOrders: 'orders/next',
    resendOrder: 'orders/next?retry='
  },
  delogue: {
    baseUrl: '/delogue/v1',
    baseUrlDev: '/deloguedev/v1',
    nextItems: 'item/next'
  },
  queryservice: {
    baseUrl: '/queryservice/v1',
    baseUrlDev: '/queryservicedev/v1',
    nextItems: 'item/next',
    nextPrices: 'price/next',
    nextWarehouse: 'warehouse/next',
    nextCustomer: 'customer/next',
  },
  bigcommerce: {
    baseUrl: '/bigcommerce/v1',
    baseUrlDev: '/bigcommercedev/v1',
    nextItems: 'item/next',
    nextPrices: 'price/next',
    nextWarehouse: 'warehouse/next',
    nextShipment: 'shipment/next',
    nextOrders: 'order/next',
  },
  centra: {
    baseUrl: '/centra/v1',
    baseUrlDev: '/centradev/v1',
    nextItems: 'items/next',
    nextPrices: 'prices/next',
    nextWarehouse: 'warehouse/next',
    nextOrders: 'orders/next',
    nextShipment: 'shipments/next',
    resendOrder: 'orders/next?retry='
  },
  nuorder: {
    baseUrl: '/nuorder/v1',
    baseUrlDev: '/nuorderdev/v1',
    nextItems: 'item/next',
    nextPrices: 'price/next',
    nextWarehouse: 'warehouse/next',
    nextShipment: 'shipment/next',
    nextOrders: 'order/next',
    nextCustomer: 'customer/next',
    resendOrder: 'order/next?retry='
  },
  optimizely: {
    baseUrl: '/optimizely/v1',
    baseUrlDev: '/optimizelydev/v1',
    nextOrders: 'order',
  },
  jetshop: {
    baseUrl: '/jetshop/v1',
    baseUrlDev: '/jetshopdev/v1',
    nextItems: 'item/next',
    nextOrders: 'order/next',
  },
  brink: {
    baseUrl: '/brink/v1',
    baseUrlDev: '/brinkdev/v1',
    nextItems: 'items/next',
    nextOrders: 'orders/next',
    nextOrdersPost: 'orders/next',
    nextPrices: 'prices/next',
    nextWarehouse: 'warehouses/next',
    nextShipment: 'shipments/next',
  },
  addimotion: {
    baseUrl: '/addimotion',
    baseUrlDev: '/addimotiondev',
    nextOrders: 'orders/next',
    nextShipment: 'shipment/next',
  },
  plytix: {
    baseUrl: '/plytix/v1',
    baseUrlDev: '/plytixdev/v1',
    nextItems: 'item/next',
    nextPrices: 'price/next',
    nextWarehouse: 'warehouse/next',
  },
  litium: {
    baseUrl: '/litium/v1',
    baseUrlDev: '/litiumdev/v1',
    nextItems: 'items/next',
    nextPrices: 'prices/next',
    nextWarehouse: 'warehouses/next',
    nextOrders: 'orders/next',
    nextShipment: 'shipments/next',
    nextCustomer: 'customers/next',
  },
  magento: {
    baseUrl: '/magento/v1',
    baseUrlDev: '/magentodev/v1',
    nextItems: 'items/next',
    nextPrices: 'prices/next',
    nextWarehouse: 'warehouses/next',
    nextOrders: 'orders/next',
    nextShipment: 'shipments/next',
    nextCustomer: 'customers/next',
  },
}