import React from 'react';

export const externalMessagesPagesConfig = [
  {
    auth: ['admin', 'user', 'viewer'],
    routes: [
      {
        path: '/externalmessages/details/:messageId',
        component: React.lazy(() => import('./Pages/ExternalMessageDetails')),
      },
      {
        path: '/externalmessages',
        component: React.lazy(() => import('./Pages/ExternalMessageList')),
      },
    ],
  },
];
