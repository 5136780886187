// Put all icon imports here for consistency

export { default as AddIcon } from '@material-ui/icons/Add';
export { default as ArrowBackIcon } from '@material-ui/icons/ArrowBack';
export { default as BackIcon } from '@material-ui/icons/ArrowBackIos';
export { default as ArrowForwardIcon, default as TenantDetailsIcon } from '@material-ui/icons/ArrowForward';
export { default as ForwardIcon } from '@material-ui/icons/ArrowForwardIos';
export { default as DataIcon } from '@material-ui/icons/Assessment';
export { default as ExternalMessageIcon, default as MessageTypeOrderIcon } from '@material-ui/icons/Assignment';
export { default as MessageTypePriceIcon } from '@material-ui/icons/AttachMoney';
export { default as RenewIcon } from '@material-ui/icons/Autorenew';
export { default as StateDebugIcon } from '@material-ui/icons/BugReport';
export { default as ConfigureIcon } from '@material-ui/icons/Build';
export { default as TenantIcon } from '@material-ui/icons/Business';
export { default as SplitIcon } from '@material-ui/icons/CallSplit';
export { default as CancelIcon } from '@material-ui/icons/Cancel';
export { default as ClearIcon } from '@material-ui/icons/Clear';
export { default as IntegrationIcon } from '@material-ui/icons/CompareArrows';
export { default as DeleteIcon } from '@material-ui/icons/Delete';
export { default as JsonIcon } from '@material-ui/icons/Description';
export { default as AcceptIcon, default as JobStatusDoneIcon, default as OkIcon, default as QueueDoneIcon } from '@material-ui/icons/Done';
export { default as EditIcon } from '@material-ui/icons/Edit';
export { default as MessageTypeCustomerIcon } from '@material-ui/icons/EmojiEmotions';
export { default as EncryptionOnIcon } from '@material-ui/icons/EnhancedEncryption';
export { default as DeadletterIcon } from '@material-ui/icons/Error';
export { default as CalendarIcon } from '@material-ui/icons/Event';
export { default as ChevronIcon } from '@material-ui/icons/ExpandMore';
export { default as CopyIcon } from '@material-ui/icons/FileCopy';
export { default as EntityIcon } from '@material-ui/icons/Fingerprint';
export { default as TenantDisabledIcon } from '@material-ui/icons/FlashOff';
export { default as ActionIcon, default as StateActiveIcon } from '@material-ui/icons/FlashOn';
export { default as NextIcon } from '@material-ui/icons/Forward';
export { default as ExecuteIcon } from '@material-ui/icons/Functions';
export { default as DownloadIcon } from '@material-ui/icons/GetApp';
export { default as HistoryIcon } from '@material-ui/icons/History';
export { default as MessageTypeWarehouseIcon } from '@material-ui/icons/LocalConvenienceStore';
export { default as MessageTypeShipmentIcon } from '@material-ui/icons/LocalShipping';
export { default as IntegrationMappingsIcon } from '@material-ui/icons/Map';
export { default as DotsIcon } from '@material-ui/icons/MoreHoriz';
export { default as MessageTypeNewIcon } from '@material-ui/icons/NewReleases';
export { default as EncryptionOffIcon } from '@material-ui/icons/NoEncryption';
export { default as EnlargeIcon } from '@material-ui/icons/PhotoSizeSelectLarge';
export { default as UploadIcon } from '@material-ui/icons/Publish';
export { default as NextIndicatorIcon } from '@material-ui/icons/Redo';
export { default as RetryQueueIcon } from '@material-ui/icons/Replay';
export { default as WarningIcon } from '@material-ui/icons/ReportProblem';
export { default as SaveIcon } from '@material-ui/icons/Save';
export { default as PasteIcon } from '@material-ui/icons/SaveAlt';
export { default as SearchIcon } from '@material-ui/icons/Search';
export { default as StateCompareIcon } from '@material-ui/icons/Settings';
export { default as RemoteIcon } from '@material-ui/icons/SettingsRemote';
export { default as MessageTypeItemIcon } from '@material-ui/icons/Speaker';
export { default as StopIcon } from '@material-ui/icons/Stop';
export { default as TenantCodeIcon } from '@material-ui/icons/Storefront';
export { default as QueueActiveIcon } from '@material-ui/icons/Subscriptions';
export { default as SyncIcon } from '@material-ui/icons/Sync';
export { default as ExternalItemIcon } from '@material-ui/icons/Translate';
export { default as ShowIcon } from '@material-ui/icons/Visibility';
export { default as HideIcon } from '@material-ui/icons/VisibilityOff';
export { default as TenantKeyIcon } from '@material-ui/icons/VpnKey';
export { default as JobStatusActiveIcon } from '@material-ui/icons/Work';
