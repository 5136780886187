import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {AuthType} from '../../shared/constants/AppEnums';
import {defaultUser} from '../../shared/constants/AppConst';
import {AuthUser} from '../../types/models/AuthUser';
import {AppActions} from '../../types';
import {Dispatch} from 'redux';
import {
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
} from '../../types/actions/Auth.actions';
import { AuthToken } from 'types/models/AuthToken';
import env from "react-dotenv";
import { ROLE_ADMIN, ROLE_USER, ROLE_VIEWER } from 'shared/utils/authRoles';

export const onJwtUserSignUp = (body: {
  email: string;
  password: string;
  name: string;
}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.post('users', body);
      localStorage.setItem('token', res.data.token);
      dispatch(setJWTToken(res.data.token));
      await loadJWTUser(dispatch);
    } catch (err) {
      console.log('error!!!!', err.response.data.error);
      dispatch(fetchError(err.response.data.error));
    }
  };
};

export const onJwtSignIn = (body: {email: string; password: string}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.post('auth', body);
      localStorage.setItem('token', res.data.token);
      dispatch(setJWTToken(res.data.token));
      await loadJWTUser(dispatch);
    } catch (err) {
      console.log('error!!!!', err.response.data.error);
      dispatch(fetchError(err.response.data.error));
    }
  };
};

export const loadJWTUser = async (dispatch: Dispatch<AppActions>) => {
  dispatch(fetchStart());
  try {
    const res = await jwtAxios.get('/auth');
    dispatch(fetchSuccess());
    console.log('res.data', res.data);
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: getUserObject(res.data),
    });
  } catch (err) {
    console.log('error!!!!', err.response.error);
    dispatch(fetchError(err.response.error));
  }
};

export const setJWTToken = (token: AuthToken | null): AppActions => ({
  type: SET_AUTH_TOKEN,
  payload: token,
});

const getUserObject = (authUser: any): AuthUser => {
  return {
    authType: AuthType.JWT_AUTH,
    displayName: authUser.displayName,
    email: authUser.mail,
    role: authUser.role,
    token: authUser.token,
    uid: authUser._id,
    photoURL: authUser.photo,
    groups: authUser.groups,
  };
};

export const onJWTAuthSignout = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchSuccess());
    setTimeout(() => {
      dispatch({type: SIGNOUT_AUTH_SUCCESS});
      dispatch(fetchSuccess());
      localStorage.removeItem('token');
    }, 500);
  };
};

export const onSetJwtToken = (token: AuthToken) => {
  return async (dispatch: Dispatch<AppActions>) => {

    try {
      localStorage.setItem('token', JSON.stringify(token));
      dispatch(setJWTToken(token));
      await onLoadJWTUser(dispatch, token);
    } catch (err) {
      console.log('onSetJwtToken error:', err);
    }
  };
};

export const onLoadJWTUser = async (dispatch: Dispatch<AppActions>, token: AuthToken) => {
  try {
    const url = env.GATEWAYPATH+`/v1/admin/me`
    const res = await jwtAxios.get(url);
    const user = {...res.data, token: token};

    // Load the environment variables which specify the groups corresponding to each role, and serialize the list of groups into an array
    if (env.ROLE_ADMIN_GROUPS == null) {
      throw new Error('ROLE_ADMIN_GROUPS environment variable is not set');
    }
    if (env.ROLE_USER_GROUPS == null) {
      throw new Error('ROLE_USER_GROUPS environment variable is not set');
    }
    if (env.ROLE_VIEWER_GROUPS == null) {
      throw new Error('ROLE_VIEWER_GROUPS environment variable is not set');
    }
    
    const roleAdminGroups = JSON.parse(env.ROLE_ADMIN_GROUPS);
    const roleUserGroups = JSON.parse(env.ROLE_USER_GROUPS);
    const roleViewerGroups = JSON.parse(env.ROLE_VIEWER_GROUPS);

    if (roleAdminGroups == null || !Array.isArray(roleAdminGroups)) {
      throw new Error('Missing environment variable ROLE_ADMIN_GROUPS (or wrongly formatted)');
    }
    if (roleUserGroups == null || !Array.isArray(roleUserGroups)) {
      throw new Error('Missing environment variable ROLE_USER_GROUPS (or wrongly formatted)');
    }
    if (roleViewerGroups == null || !Array.isArray(roleViewerGroups)) {
      throw new Error('Missing environment variable ROLE_VIEWER_GROUPS (or wrongly formatted)');
    }

    // Check if any element matches in user.groups and roleAdminGroups, as well as roleUserGroups
    const isAdmin = roleAdminGroups.some(group => user.groups.includes(group));
    const isUser = roleUserGroups.some(group => user.groups.includes(group));
    const isViewer = roleViewerGroups.some(group => user.groups.includes(group));
    
    // If the user is an admin, set the role to admin, otherwise set it to user
    user.role = []
    if (isAdmin) user.role.push(ROLE_ADMIN)
    if (isUser) user.role.push(ROLE_USER)
    if (isViewer) user.role.push(ROLE_VIEWER)

    dispatch({
      type: UPDATE_AUTH_USER,
      payload: getUserObject(user),
    });
  } catch (err) {
    console.log('onLoadJWTUser error:', err);
  }
};

export const onMockSetJwtToken = (token: AuthToken) => {
  return async (dispatch: Dispatch<AppActions>) => {

    try {
      localStorage.setItem('token', JSON.stringify(token));
      dispatch(setJWTToken(token));
      await onMockLoadJWTUser(dispatch, token);
    } catch (err) {
      console.log('onMockSetJwtToken error:', err);
    }
  };
};

export const onMockLoadJWTUser = async (dispatch: Dispatch<AppActions>, token: AuthToken) => {
  try {

    const mockUser = {
      displayName: "Mock Mocksson",
      email: "mock@mockery.mock",
      role: defaultUser.role,
      token: token,
      uid: "8ab9e5a2-31af-4f03-bff1-ebe789be4f4b",
      photoURL: "",
    };

    dispatch({
      type: UPDATE_AUTH_USER,
      payload: getUserObject(mockUser),
    });
  } catch (err) {
    console.log('onMockLoadJWTUser error:', err);
  }
};